import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { userFeaturesPermissionsState } from "./atoms";
import { useAtomValue } from "jotai";
import { PAGE_ACCESS } from "./constants/featuresAndPermissions";
import lazyWithRetry from "./lazyRetry";
import AuthLayout from "./common/layout/AuthLayout";
import PageLoader from "./components/page-loader";

//-Projects------
const Invite = lazyWithRetry(() => import("./pages/invite"));
const Analytics = lazyWithRetry(() => import("./pages/analytics"));
// const Project = lazyWithRetry(() => import("./pages/project-detail"));
const ProjectDetail = lazyWithRetry(() => import("./pages/project-detail"));
const Projects = lazyWithRetry(() => import("./pages/projects"));

const OrganizationLibrary = lazyWithRetry(() =>
  import("./pages/organization-library")
);

//-Product------
const CreateProduct = lazyWithRetry(() =>
  import("./pages/products/create-product")
);
const UpdateProduct = lazyWithRetry(() =>
  import("./pages/products/update-product")
);
const Products = lazyWithRetry(() => import("./pages/products"));
const Product = lazyWithRetry(() => import("./pages/product-detail"));
const Team = lazyWithRetry(() => import("./pages/team"));
const Specs = lazyWithRetry(() => import("./pages/specs"));
const Login = lazyWithRetry(() => import("./pages/login"));
const Register = lazyWithRetry(() => import("./pages/register"));
const Verification = lazyWithRetry(() => import("./pages/verification"));
const ResetPassword = lazyWithRetry(() => import("./pages/reset-password"))

//-Settings------
const PersonalSettings = lazyWithRetry(() => import("./pages/settings"));
const OrganizationSettings = lazyWithRetry(() => import("./pages/settings/organization"));
const Actions = lazyWithRetry(() => import("./pages/settings/actions"));

//Auth
const AutodeskAuth = lazyWithRetry(() =>
  import("./components/login/autodesk-auth")
);
  
const Routes = ({ authError }) => { 
  
  // global state
  const { pageAccess } = useAtomValue(userFeaturesPermissionsState);

  return (
    <Router>
      <Switch>

        <Route
          path="/autodesk-auth"
          exact
          render={(props) => (
            <React.Suspense fallback={<PageLoader />}>
              <AutodeskAuth {...props} />
            </React.Suspense>
          )}
        />

        <Route
          path="/signin"
          exact
          render={(props) => (
            <React.Suspense fallback={<PageLoader />}>
              <AuthLayout title="Log in" withHeader>
                <Login authError={authError} {...props} />
              </AuthLayout>
            </React.Suspense>
          )}
        />

        <Route
          path="/signup"
          exact
          render={(props) => (
            <React.Suspense fallback={<PageLoader />}>
              <AuthLayout title="Sign up" withHeader>
                <Register {...props} />
              </AuthLayout>
            </React.Suspense>
          )}
        />

        <Route
          path="/reset-password"
          exact
          render={(props) => (
            <React.Suspense fallback={<PageLoader />}>
              <AuthLayout title="Sign up" withHeader>
                <ResetPassword {...props} />
              </AuthLayout>
            </React.Suspense>
          )}
        />

        <Route
          path="/verification"
          exact
          render={(props) => (
            <React.Suspense fallback={<PageLoader />}>
              <AuthLayout title="Sign up" withHeader>
                <Verification type={"email"} {...props} />
              </AuthLayout>
            </React.Suspense>
          )}
        />

        <Route
          path="/invite/:type/:token"
          exact
          render={(props) => (
            <React.Suspense fallback={<PageLoader />}>
              <Invite {...props} />
            </React.Suspense>
          )}
        />

        <Route
          path="/settings/actions"
          exact
          render={(props) => (
            <React.Suspense fallback={<PageLoader />}>
              <Actions {...props} />
            </React.Suspense>
          )}
        />

        <PrivateRoute withoutLayout={true} path="/protection" exact>
          <AuthLayout title="Sign in" otp={true}>
            <Verification type={"otp"} />
          </AuthLayout>
        </PrivateRoute>

        <PrivateRoute path="/" exact>
          <Redirect to={{ pathname: "/products" }} />
        </PrivateRoute>

        <PrivateRoute path="/analytics" exact authAccess={!!pageAccess?.[PAGE_ACCESS.ANALYTICS]}>
          <Analytics />
        </PrivateRoute>

        <PrivateRoute path="/projects" exact authAccess={!!pageAccess?.[PAGE_ACCESS.PROJECTS]}>
          <Projects />
        </PrivateRoute>

        <PrivateRoute path="/project/:id" exact authAcces={!!pageAccess?.[PAGE_ACCESS.PROJECT_DETAIL]}>
          <ProjectDetail />
        </PrivateRoute>

        <PrivateRoute path="/products" exact authAccess={!!pageAccess?.[PAGE_ACCESS.PRODUCTS]}>
          <Products />
        </PrivateRoute>

        <PrivateRoute path="/products/:id" exact authAccess={!!pageAccess?.[PAGE_ACCESS.PRODUCT_DETAIL]}>
          <Product />
        </PrivateRoute>

        <PrivateRoute path="/settings" exact>
          <Redirect to={{ pathname: "/settings/personal" }} />
        </PrivateRoute>

        <PrivateRoute path="/settings/personal" exact authAccess={!!pageAccess?.[PAGE_ACCESS.PERSONAL_SETTINGS]}>
          <PersonalSettings />
        </PrivateRoute>

        <PrivateRoute path="/settings/organization" exact authAccess={!!pageAccess?.[PAGE_ACCESS.ORGANIZATION_SETTINGS]}>
          <OrganizationSettings />
        </PrivateRoute>

        <PrivateRoute path="/product/create" exact authAccess={!!pageAccess?.[PAGE_ACCESS.CREATE_PRODUCT]} withoutLayout >
          <CreateProduct />
        </PrivateRoute>

        <PrivateRoute path="/product/update/:id" exact authAccess={!!pageAccess?.[PAGE_ACCESS.UPDATE_PRODUCT]} withoutLayout >
          <UpdateProduct />
        </PrivateRoute>

        <PrivateRoute path="/organization-items" exact authAccess={!!pageAccess?.[PAGE_ACCESS.ORGANIZATION_DB]}>
          <OrganizationLibrary />
        </PrivateRoute>

        <PrivateRoute path="/team" exact authAccess={!!pageAccess?.[PAGE_ACCESS.TEAM]}>
          <Team />
        </PrivateRoute>

        <PrivateRoute path="/specs" exact authAccess={!!pageAccess?.[PAGE_ACCESS.SPECS]}>
          <Specs />
        </PrivateRoute>

        <Route path="*" render={() => <div> Page Not Found </div>} />
      </Switch>
    </Router>
  );
};

export default Routes;
