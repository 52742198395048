import axios from "axios";
import * as Sentry from "@sentry/react";
import { commonAuthHeaders } from "../auth/utils";

const API_URL = `${process.env.REACT_APP_APIS_URL}/apis`;

const instance = axios.create({
  baseURL: API_URL,
  timeout: 3600000,
});

instance.interceptors.request.use(
  async (config) => {
    config.headers = await commonAuthHeaders()
    return config;
  },
  (error) => {
    Sentry.captureException(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAutodeskToken = async () => {
  return instance.get("/bim/token");
};

export const uploadFile = async ({ data }, { onUploadProgress, headers }) => {
  const res = await instance.post("/bim/upload-file", data, {
    onUploadProgress,
    headers,
  });
  return res;
};

export const createProject = async (
  { data },
  { onUploadProgress, headers, cancelToken }
) => {
  const res = await instance.post("/bim/create-project", data, {
    onUploadProgress,
    headers,
    cancelToken,
  });
  return res;
};

export const uploadProjectFile = async (
  { data },
  { onUploadProgress, headers }
) => {
  const res = await instance.post("/bim/project/upload", data, {
    onUploadProgress,
    headers,
  });
  return res;
};

export const generateBOM = async (projectId, viewId) => {
  const res = await instance.post(
    `/bom/project/generate-bom/${projectId}${viewId ? `?viewId=${viewId}` : ""}`
  );
  return res;
};

export const getProjectManifest = async ({ urn, region }) => {
  const result = await instance.get(`/bim/get/manifest/${urn}`, {
    params: {
      region,
    },
  });
  return result;
};

export const getDownloadUrl = (key) => {
  return `${API_URL}/custom/product/download?key=${key}`;
};

export const download = (key, extension) => {
  let name = key && key.split("/");
  name = name[name.length - 1];
  instance
    .request({
      url: `${API_URL}/custom/product/download?link=${key}&as=${extension}`,
      method: "GET",
      responseType: "blob",
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${name}.${extension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err) => {
      Sentry.captureException(err);
    });
};

export const getFileNameFromAwsKey = (key) => key ? key.split("/").slice(-1)[0] : key;

export const downloadMultipleFiles = async (keys) => {
  const filesData = keys.filter(Boolean).map((key) => ({
    url: getDownloadUrl(key),
    fileName: getFileNameFromAwsKey(key)
  }));

  filesData.forEach(async function (fileData) {
    try {
      const res = await fetch(fileData.url)
      const blob = await res.blob(); // Gets the response and returns it as a blob
      window.saveAs(blob, fileData.fileName)
    } catch (error) {
      console.error(error)
    }
  });
};

export const bomExcelExport = (projectId, detailed = false) => {
  return new Promise((resolve, reject) => {
    instance.request({
      url: `${API_URL}/bom/project/excel/${projectId}${detailed ? "/detailed" : ""}`,
      method: "GET",
      responseType: "blob",
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${projectId}-${detailed ? "detailed" : "lean"}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve(true);
    })
      .catch((err) => {
        Sentry.captureException(err);
        reject(err);
      });
  });
};

export const privateCatalogExcelExport = (orgId) => {
  instance
    .request({
      url: `${API_URL}/cms/export/catalog/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        organizationId: orgId,
      },
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${orgId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err) => {
      Sentry.captureException(err);
    });
};

export const privateCatalogProductExcelExport = (productId) => {
  instance
    .request({
      url: `${API_URL}/cms/export/product/${productId}/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        id: productId,
      },
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${productId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err) => {
      Sentry.captureException(err);
    });
};

export const sandboxExcelExport = (projectId, viewId) => {
  instance
    .request({
      url: `${API_URL}/house/${projectId}/${viewId}/materials`,
      method: "GET",
      responseType: "blob"
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${projectId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err) => {
      Sentry.captureException(err);
    });
};

export const getGeoCordinates = () => {
  return instance.get(`${API_URL}/custom/location`);
};

export const getMixpanelEventData = ({
  projectId,
  from_date: fromDate,
  to_date: toDate,
  event,
  where,
}) => {
  let obj = {
    from_date: fromDate,
    to_date: toDate,
  };
  if (event) {
    obj.event = event;
  }
  if (where) {
    obj.where = where;
  }
  return instance.get(`/mixpanel/project/${projectId}`, {
    params: obj,
  });
};

export const fetchBomData = async (id, viewId, objectId = "ROOT") => {
  const { data } = await instance.get(`/house/${id}/bom`, {
    params: {
      viewId,
      objectId,
    },
  });
  return data;
};

export const openPdf = (pdfFile = {}, pageNumber) => {
  let url = `${API_URL}/custom/pdf-viewer?bucketName=${pdfFile.Bucket}&key=${pdfFile.Key}`;
  if (pageNumber) {
    url += `#page=${pageNumber}`
  }
  window.open(url, '_blank');
}
