import React from 'react'
import Tag from '../tag'

const KeyValuePair = ({ keyName, value, children, type = "", withColon = true, highlightedKeyName }) => {
  return (
    <div className={`bimmatch-key-value-pair ${React.isValidElement(children) ? "custom" : type}-property-value-wrapper`}>
      {
        type === "hierarchy" ? (
          <HierarchyKeyValueField keyName={keyName} value={value} highlightedKeyName={highlightedKeyName} />
        ) : (
          <>
              <KeyField type={type} keyName={keyName} withColon={withColon} />
              {!!children ? children : <ValueField type={type} value={value} />}
          </>
        )
      }
    </div>
  )
}

const KeyField = ({ type, keyName, withColon, highlightedKeyName }) => {
  return <span className={`key-field value-field-${type}`}>{keyName}{highlightedKeyName ? (<span className='highlighted-key-name'>{highlightedKeyName}</span>) : (<></>)}{withColon ? ":" : ""}</span>
}

const ValueField = ({ type, value }) => {
  switch (type) {
    case "tags":
      return (
        <div className='tags-wrapper'>
          {value.map(tagData => <Tag key={tagData.id} name={tagData.displayName} color={tagData?.color || null} />)}
        </div>
      )
    default:
      return (
        <span className={`value-field value-field-${type}`}>{formatValue(type, value)}</span>
      )
  }
}

export const HierarchyKeyValueField = ({ keyName, value = {}, highlightedKeyName }) => {
  return (
    <div className='hierarchy-key-value-field'>
      <KeyField keyName={keyName} withColon={false} type={"hierarchy"} highlightedKeyName={highlightedKeyName} />
      <div className='hierarchy-value-field'>
        {Object.values(value).map(childData => (
          <KeyValuePair key={childData.key} keyName={childData.keyName} value={childData.value} type={childData.type} />
        ))}
      </div>
    </div>
  )
}

const formatValue = (type, value) => {
  switch (type) {
    case "array":
      return value.join(", ");
    case "boolean":
      return value ? "Yes" : "No"
    default:
      return ((typeof value === "string") ? value : JSON.stringify(value))
  }
}

export default KeyValuePair