import React, { useMemo } from 'react'
import PriorityDescription from '../priority-description'

const GroupedPriorityDescription = ({ subSectionData, showTitle = true }) => {
    const { title, subSubSections } = useMemo(() => subSectionData, [subSectionData])
    const SubSections = useMemo(() => subSubSections.map(subSectionData => <PriorityDescription key={subSectionData.id} type={subSectionData.importanceType} count={subSectionData.importance} title={subSectionData.title} tags={[...subSectionData.categories, ...subSectionData.materials, ...subSectionData.standards]} parsedContent={subSectionData.parsedContent} hideCount />), [subSubSections])
    return (
        <div className='grouped-priority-description'>
            {(showTitle && title) && <div className='grouped-priority-description-header'>{title}</div>}
            <div className='specs-childs'>{SubSections}</div>
        </div>
    )
}

export default GroupedPriorityDescription