import React, { Fragment, useMemo } from 'react'
import Chip from '../chip'
import Tag from '../tag'
import { getImgLocation } from '../../utils'
import ImageCard from '../image-card'

const PriorityDescription = ({ type, count = 0, title, tags = [], hideCount = false, parsedContent }) => {
    const priorityType = useMemo(() => type ? type : (isNaN(count) ? "info" : ((count <= 3) ? "info" : ((count <= 7) ? "warning" : "danger"))), [type, count])

    return (
        <div className='bimmatch-priority-description'>
            {!!title && <pre className='description'>{title}</pre>}
            {Array.isArray(parsedContent) && (
                <ParsedContent parsedContent={parsedContent} />
            )}
            <div className='importance-tag-wrapper'>
                {!hideCount && (
                    <div className='importance-wrapper'>
                        <Chip iconSrc={`/images/icon/exclamation-${priorityType}.svg`} name={count} />
                    </div>
                )}
                <div className='tags-wrapper'>{tags.map(tagData => (<Tag key={tagData.id} name={tagData.displayName} color={tagData?.color || null} />))}</div>
            </div>
        </div>
    )
}

const ParsedContent = ({ parsedContent }) => {
    return (
        <Fragment>
            {parsedContent.map((parsedContentData, index) => (
                <Fragment key={index}>
                    {parsedContentData.type === "text" && <pre className='description'>{parsedContentData?.text}</pre>}
                    {((parsedContentData.type === "table") || (parsedContentData.type === "figure")) && (
                        <ImageCard
                            className={`parsed-content-image parsed-content-image-${parsedContentData.type}`}
                            src={getImgLocation(parsedContentData?.imgFile)?.url}
                            alt={parsedContentData?.text || `${index}}`}
                            width="100%"
                            height="auto"
                        />
                    )}
                </Fragment>
            ))}
        </Fragment>
    )
}

export default PriorityDescription