import React, { isValidElement, useCallback, useState } from 'react';
import ImagePreview from '../image-preview';

const ImageCard = ({ title = '', src, alt, height = 250, width = 500, description = '', descriptionTitle = '', zoom = true, footerContent, className = '', ...props }) => {

    const [isZoomEnabled, setIsZoomEnabled] = useState(false)

    // enabling or disabling zoom
    const onZoomToggle = useCallback(() => {
        setIsZoomEnabled((prevZoomState => !prevZoomState))
    }, [])

    return (
        <>
            <div className={`image-card ${!zoom ? 'image-card-normal' : 'image-card-zoom'} ${className}`} {...props}>
                <h5 className='image-name truncate-text'>{title}</h5>
                {description && (
                    <p className='image-description'>
                        {descriptionTitle && (<span className='title'>{descriptionTitle}:</span>)}
                        <span className='description' title={description}>{description}</span>
                    </p>
                )}
                <div className='image-card-main'>
                    <img className='image-card-image' height={height} width={width} src={src} alt={alt || 'image-card'} />
                    {zoom && (
                        <div className='background-zoom' onClick={onZoomToggle}>
                            <img className='image-zoom' src={'/images/icon/maximize.svg'} alt={'card-zoom'} />
                        </div>
                    )}
                </div>
                {isValidElement(footerContent) && footerContent}
            </div>

            {isZoomEnabled && (
                <ImagePreview visible={isZoomEnabled} onClose={onZoomToggle} src={src} alt={src} title={title} footerContent={footerContent} />
            )}
        </>
    );
}

export default ImageCard;